import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { UserAdService } from '../main/service/user-ad.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[] = [];
    check: boolean = false;
    _aafcmeServices: MenuItem[] = [];
    _aafcmeConventions: MenuItem[] = [];

    finalMenuItemsCollections: MenuItem[] = [];

    isPresident: boolean = false;
    hasAccesToSuppliers: boolean = false;
    hasAccesToDataInjection: boolean = false;
    hasAccesSolfaBudget: boolean = false;
    hasAccesToStockOfficiel: boolean = false;
    hasAccesToResaReporting: boolean = false;
    @Input() userConnected: any;
    connectedUser: any;
    Nch_account: string = "Super Admin";
    PRESIDENT: string = "President";
    BACK_OFFICE: string = "Back-Office";
    Financial: string = 'Comptabilité';
    REGIONAL: string = "Regional-Board";
    isFinancialAccount: boolean = false;

    hasAccesToInvoice: boolean = false;

    constructor(private _userAd: UserAdService) {
        this._userAd.connectedUserAD$
            .subscribe({
                next: (_connectedUser: any) => {
                    if (_connectedUser) {
                        this.connectedUser = _connectedUser;
                    }
                }
            });
    }

    redirectToSignIn() {
        window.open(environment.bookingUrl, '_blank');
    }

    ngOnInit() { 
        debugger
        let userGroupsStringCollections: string[] = [];
        if (this.userConnected) {
            this.userConnected.userGroups.map(
                (_: any) => {
                    if (_) {
                        userGroupsStringCollections.unshift(_.displayName);
                    }
                }
            );
            if (userGroupsStringCollections.includes('Regional-Board') || userGroupsStringCollections.includes(this.BACK_OFFICE)) { this.hasAccesToSuppliers = false; } else { this.hasAccesToSuppliers = true; }
            if (userGroupsStringCollections.includes('Super Admin') || userGroupsStringCollections.includes('NCH-TECH')) { this.hasAccesToDataInjection = true; } else { this.hasAccesToDataInjection = false; }
            if (userGroupsStringCollections.includes('Regional-Board') || userGroupsStringCollections.includes('Back-Office')) { this.hasAccesToSuppliers = false; } else { this.hasAccesToSuppliers = true; }
            if (userGroupsStringCollections.includes('Super Admin')) { this.hasAccesToStockOfficiel = true; } else { this.hasAccesToStockOfficiel = false; }
            if (userGroupsStringCollections.includes(this.Financial)) { this.isFinancialAccount = true; } else { this.isFinancialAccount = false; }
            if (userGroupsStringCollections.includes('Regional-Board') || userGroupsStringCollections.includes('Back-Office')) { this.hasAccesSolfaBudget = false; } else { this.hasAccesSolfaBudget = true; }
            if (userGroupsStringCollections.includes('Super Admin') || userGroupsStringCollections.includes('NCH-TECH')
                || (!userGroupsStringCollections.includes(this.BACK_OFFICE) && !userGroupsStringCollections.includes(this.REGIONAL))
            ) { this.hasAccesToResaReporting = true; } else { this.hasAccesToResaReporting = false; }
            if (userGroupsStringCollections.includes('Super Admin') || userGroupsStringCollections.includes('Executive-Board') || userGroupsStringCollections.includes('Comptabilité') ) { this.hasAccesToInvoice = true; } else { this.hasAccesToInvoice = false; }

        }

        if (this.isFinancialAccount) {
            this.model = [
                {
                    label: "Fournisseurs",
                    visible: this.hasAccesToSuppliers,
                    fragment: 'assets/demo/images/pipeline.png',
                    items: [
                        {
                            label: 'Ooredoo',
                            fragment: 'assets/demo/images/ooredoo.png',
                            items: [
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-suppliers/ooredoo-area/requests']
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-suppliers/ooredoo-area/reporting']
                                }
                            ]
                        },
                        {
                            label: 'Autres fournisseurs',
                            fragment: 'assets/demo/images/other.png',
                            items: [
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-suppliers/extra-suppliers-area/requests']
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-suppliers/extra-suppliers-area/reporting']
                                }

                            ]
                        },
                        {
                            label: "Facturation",
                            fragment: 'assets/demo/images/invoice.png',
                            visible: this.hasAccesToInvoice,
                            items: [
                                {
                                    label: 'Consultation des Factures',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-invoice-services/home'],
                                },
                            ]
                        }
                    ]
                }
            ];
        } else {

            this.model = [
                {
                    label: "Vue 360°",
                    fragment: 'assets/demo/images/metaverse.png',
                    items: [
                        {
                            label: 'Fiche Adhérent',
                            fragment: 'assets/demo/images/details.png',
                            routerLink: '/app-layout/M-global-view/search-employee'
                        }
                        ,
                        {
                            label: 'Consultation des demandes',
                            fragment: 'assets/demo/images/suivi.png',
                            items: [
                                {
                                    label: 'Suivi demande màj',
                                    icon: 'pi pi-fw pi-th-large',
                                    routerLink: '/app-layout/M-global-view/summary-requests/requests-update'
                                },
                                {
                                    label: 'Reporting adhésion',
                                    icon: 'pi pi-fw pi-verified',
                                    routerLink: ['/app-layout/M-global-view/summary-requests/reporting-requests']
                                }
                            ]
                        }
                    ]
                }
                ,
                {
                    label: 'Services',
                    fragment: 'assets/demo/images/share.png',
                    expanded: false,
                    items: [
                        {
                            label: 'Service Solfa',
                            fragment: 'assets/demo/images/s.png',
                            items: [
                                {
                                    label: 'Nouvelle Demande',
                                    fragment: 'assets/demo/images/new-solfa.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/solfa-services/main-request']
                                },
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/solfa-services/solfa-requests'],
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/solfa-services/solfa-reporting'],
                                },
                                {
                                    label: 'Suivi Budget',
                                    fragment: 'assets/demo/images/budget.png',
                                    visible: this.hasAccesSolfaBudget,
                                    items: [
                                        {
                                            label: 'Ajout budget initial',
                                            visible: this.hasAccesSolfaBudget,
                                            fragment: 'assets/demo/images/addBudget.png',
                                            routerLink: ['/app-layout/M-Aafcme-services/sfs/budget-monitoring/add-budget']
                                        },
                                        {
                                            label: ' Suivi Consommation',
                                            fragment: 'assets/demo/images/SuiviConsommation.png',
                                            visible: this.hasAccesSolfaBudget,
                                            routerLink: ['/app-layout/M-Aafcme-services/sfs/budget-monitoring/consumption-monitoring'],
                                        }

                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Solfa Allouche',
                            fragment: 'assets/demo/images/sa.png',
                            items: [
                                {
                                    label: 'Nouvelle Demande',
                                    fragment: 'assets/demo/images/new-solfa.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/sa-services/main-request-sa/search-employee-sa']
                                },
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/sa-services/sa-requests'],
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/sa-services/sa-reporting'],
                                },
                                {
                                    label: 'Suivi Budget',
                                    fragment: 'assets/demo/images/budget.png',
                                    visible: this.hasAccesSolfaBudget,
                                    items: [
                                        {
                                            label: 'Ajout budget initial',
                                            visible: this.hasAccesSolfaBudget,
                                            fragment: 'assets/demo/images/addBudget.png',
                                            routerLink: ['/app-layout/M-Aafcme-services/safs/budget-monitoring/add-budget']
                                        },
                                        {
                                            label: ' Suivi Consommation',
                                            fragment: 'assets/demo/images/SuiviConsommation.png',
                                            visible: this.hasAccesSolfaBudget,
                                            routerLink: ['/app-layout/M-Aafcme-services/safs/budget-monitoring/consumption-monitoring'],
                                        }

                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Résa',
                            fragment: 'assets/demo/images/r.png',
                            items: [
                                {
                                    label: 'Nouvelle réservation',
                                    fragment: 'assets/demo/images/reserver.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/resa-services/main-request-resa/search-employee-resa']
                                },
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-requests'],
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-reporting'],
                                },
                                {
                                    label: 'Reporting Résidence',
                                    fragment: 'assets/demo/images/reporting.png',
                                    visible: this.hasAccesToResaReporting,
                                    routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-reporting-appartment'],
                                },
                                {
                                    label: 'Calendrier Résidence',
                                    fragment: 'assets/demo/images/calandar.png',
                                    visible: this.hasAccesToResaReporting,
                                    routerLink: ['/app-layout/M-Aafcme-services/resa-services/resa-calendar'],
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Market Place',
                    fragment: 'assets/demo/images/bags.png',
                    items: [
                        {
                            label: 'Officiel',
                            fragment: 'assets/demo/images/officiel.png',
                            items: [
                                {
                                    label: 'Nouvelle Demande',
                                    fragment: 'assets/demo/images/order.png',
                                    routerLink: ['/app-layout/M-market-place/officiel-services/main-request/search-employee']
                                },
                                {
                                    label: 'Suivi des demandes',
                                    fragment: 'assets/demo/images/purchase-order-officiel.png',
                                    routerLink: ['/app-layout/M-market-place/officiel-services/officiel-requests'],
                                },

                                {
                                    label: 'Suivi dérogations',
                                    fragment: 'assets/demo/images/derog.png',
                                    routerLink: ['/app-layout/M-market-place/officiel-services/follow-up-exceptionnal-requests']
                                },
                                {
                                    label: 'Reporting ',
                                    fragment: 'assets/demo/images/Reporting-officiel.png',
                                    routerLink: ['/app-layout/M-market-place/officiel-services/officiel-reporting'],
                                },
                              
                                {
                                    label: 'Gestion du stock',
                                    fragment: 'assets/demo/images/stock-availability.png',
                                    visible: this.hasAccesToStockOfficiel,
                                    items: [
                                       
                                        {
                                            label: 'Ajout produits',
                                            fragment: 'assets/demo/images/add-product.png',
                                            visible: this.hasAccesToStockOfficiel,
                                            routerLink: ['/app-layout/M-market-place/officiel-services/stock-top-bar/add-product'],
                                        },
                                        {
                                            label: 'MAJ produits',
                                            fragment: 'assets/demo/images/edit-info.png',
                                            visible: this.hasAccesToStockOfficiel,
                                            routerLink: ['/app-layout/M-market-place/officiel-services/stock-top-bar/update-product'],
                                        },
                                        {
                                            label: 'Etat du stock',
                                            fragment: 'assets/demo/images/9226554.png',
                                            visible: this.hasAccesToStockOfficiel,
                                            routerLink: ['/app-layout/M-market-place/officiel-services/stock-top-bar/stock-availibility'],
                                        }

                                    ]
                                }


                            ]
                        },
                        // {
                        //     label: "Voyage à l'étranger",
                        //     fragment: 'assets/demo/images/Voyage.png',
                        //     items: [
                        //         {
                        //             label: 'Nouvelle Demande',
                        //             fragment: 'assets/demo/images/newTravel.png',
                        //             routerLink: ['/app-layout/M-traveling-aboard/booking-services/booking-reporting'],
                        //             command: () => this.redirectToSignIn(),
                        //         },
                        //         {
                        //             label: 'Suivi des demandes',
                        //             fragment: 'assets/demo/images/suiviTravel.png',
                        //             routerLink: ['/app-layout/M-traveling-aboard/booking-services/booking-requests'],
                        //         },
                        //         {
                        //             label: 'Reporting demandes',
                        //             fragment: 'assets/demo/images/reserve.png',
                        //             routerLink: ['/app-layout/M-traveling-aboard/booking-services/booking-reporting'],
                        //         }
                        //     ]
                        // }
                    ]
                },
                {
                    label: "Gestion d'achat",
                    fragment: 'assets/demo/images/management.png',
                    items: [
                        {
                            label: 'Nouvel achat',
                            fragment: 'assets/demo/images/new-purchase.png',
                            routerLink: ['/app-layout/M-purchase/purchase-management/main-purchase-layout/search-employee']
                        },
                        {
                            label: 'Suivi demandes',
                            fragment: 'assets/demo/images/suivi.png',
                            routerLink: ['/app-layout/M-purchase/purchase-management/requests']
                        },
                        {
                            label: 'Reporting',
                            fragment: 'assets/demo/images/reporting.png',
                            routerLink: ['/app-layout/M-purchase/purchase-management/reporting']
                        }
                    ]
                }
                ,
                {
                    label: "Fournisseurs",
                    visible: this.hasAccesToSuppliers,
                    fragment: 'assets/demo/images/pipeline.png',
                    items: [
                        {
                            label: 'Ooredoo',
                            fragment: 'assets/demo/images/ooredoo.png',
                            items: [
                                {
                                    label: 'Suivi dérogations',
                                    fragment: 'assets/demo/images/derog.png',
                                    routerLink: ['/app-layout/M-suppliers/ooredoo-area/exceptionnal-purchases']
                                },
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-suppliers/ooredoo-area/requests']
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-suppliers/ooredoo-area/reporting']
                                }
                            ]
                        },
                        {
                            label: 'Autres fournisseurs',
                            fragment: 'assets/demo/images/other.png',
                            items: [
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-suppliers/extra-suppliers-area/requests']
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-suppliers/extra-suppliers-area/reporting']
                                }

                            ]
                        },
                        {
                            label: "Facturation",
                            fragment: 'assets/demo/images/invoice.png',
                            visible: this.hasAccesToInvoice,
                            items: [
                                {
                                    label: 'Consultation des Factures',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-invoice-services/home'],
                                },
                            ]
                        }

                    ]
                },
                {
                    label: "BO & Support",
                    fragment: 'assets/demo/images/support.png',
                    items: [
                        {
                            label: "Back Office",
                            fragment: 'assets/demo/images/adaptive.png',
                            items: [
                                {
                                    label: 'Suivi demandes',
                                    fragment: 'assets/demo/images/suivi.png',
                                    routerLink: ['/app-layout/M-back-office/tracking/tracking-ppa']
                                },
                                {
                                    label: 'Reporting',
                                    fragment: 'assets/demo/images/reporting.png',
                                    routerLink: ['/app-layout/M-back-office/reporting']
                                }
                            ]
                        },
                        {
                            label: "Injection Data",
                            fragment: 'assets/demo/images/injection-data.png',
                            visible: this.hasAccesToDataInjection,
                            items: [
                                {
                                    label: 'Transactions Financières',
                                    routerLink: ['/app-layout/M-azure-function/financial-transactions'],
                                },
                                {
                                    label: 'Employes',
                                    routerLink: ['/app-layout/M-azure-function/employees-management'],
                                },
                                {
                                    label: 'Data Conventions',
                                    routerLink: ['/app-layout/M-azure-function/data-conventions'],
                                },
                            ]
                        },
                        {
                            label: "Interventions Manuelles",
                            fragment: 'assets/demo/images/Interventions-manuelles.png',
                            visible: this.hasAccesToDataInjection,
                            items: [
                                {
                                    label: 'Clôture & Historique',
                                    fragment: 'assets/demo/images/historique.png',
                                    routerLink: ['/app-layout/M-interventions-manuelles/home'],
                                },
                                {
                                    label: 'Arrêt/suppression cessions',
                                    fragment: 'assets/demo/images/arret.png',
                                    routerLink: ['/app-layout/M-interventions-manuelles/supp-cessions'],
                                },
                                {
                                    label: 'Modification cessions',
                                    fragment: 'assets/demo/images/update.png',
                                    routerLink: ['/app-layout/M-interventions-manuelles/modi-cessions'],
                                },
                                {
                                    label: 'Ajout Cessions',
                                    fragment: 'assets/demo/images/declare.png',
                                    routerLink: ['/app-layout/M-interventions-manuelles/cessions-non-realise'],
                                },
                                {
                                    label: 'Report cessions',
                                    fragment: 'assets/demo/images/statut.png',
                                    routerLink: ['/app-layout/M-interventions-manuelles/report-cessions'],
                                },
                                {
                                    label: 'Remboursements',
                                    fragment: 'assets/demo/images/remboursements.png',
                                    routerLink: ['/app-layout/M-interventions-manuelles/remboursements'],
                                },
                            ]
                        },
                    ]
                }
                ,
                // {
                //     label: "Back Office",
                //     fragment: 'assets/demo/images/adaptive.png',
                //     items: [
                //         {
                //             label: 'Suivi demandes',
                //             fragment: 'assets/demo/images/suivi.png',
                //             routerLink: ['/app-layout/M-back-office/tracking/tracking-ppa']
                //         },
                //         {
                //             label: 'Reporting',
                //             fragment: 'assets/demo/images/reporting.png',
                //             routerLink: ['/app-layout/M-back-office/reporting']
                //         }
                //     ]
                // },
                // {
                //     label: "Injection Data",
                //     fragment: 'assets/demo/images/injection-data.png',
                //     visible: this.hasAccesToDataInjection,
                //     items: [
                //         {
                //             label: 'Transactions Financières',
                //             routerLink: ['/app-layout/M-azure-function/financial-transactions'],
                //         },
                //         {
                //             label: 'Employes',
                //             routerLink: ['/app-layout/M-azure-function/employees-management'],
                //         },
                //         {
                //             label: 'Data Conventions',
                //             routerLink: ['/app-layout/M-azure-function/data-conventions'],
                //         },
                //     ]
                // },
                // {
                //     label: "Interventions Manuelles",
                //     fragment: 'assets/demo/images/Interventions-manuelles.png',
                //     visible: this.hasAccesToDataInjection,
                //     items: [
                //         {
                //             label: 'Arrêt/suppression cessions',
                //             routerLink: ['/app-layout/M-interventions-manuelles/supp-cessions'],
                //         },
                //         {
                //             label: 'Modification cessions',
                //             routerLink: ['/app-layout/M-interventions-manuelles/modi-cessions'],
                //         },
                //         {
                //             label: 'Ajout Cessions',
                //             routerLink: ['/app-layout/M-interventions-manuelles/cessions-non-realise'],
                //         },
                //         {
                //             label: 'Remboursements',
                //             routerLink: ['/app-layout/M-interventions-manuelles/remboursements'],
                //         },{
                //             label: 'Report cessions',
                //             routerLink: ['/app-layout/M-interventions-manuelles/report-cessions'],
                //         },
                //     ]
                // }
            ];
        }
    }
}
