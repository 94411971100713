
import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1
    ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

export const auth_sso_configuration: Configuration = {
    auth: {
        clientId: 'b0e06ffd-3704-4c1c-8079-520f043aa2cc',
        authority: 'https://login.microsoftonline.com/20923150-f613-4b38-af27-a5dd1cb27af1',
        redirectUri: '/signin-oidc',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
        secureCookies: true
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                // console.log(logLevel, message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const AUTH_SSO_MSAL = {
    protectedResourceMap: new Map([
        ['https://prodconvamiaafcmeapi.azurewebsites.net/*', ['api://a6e530ca-09a0-4156-aebe-1d033b33eec0/ConvAmi.AACME.API-Scope']],
        ['https://intconvamiaafcmeapi.azurewebsites.net/*', ['api://a6e530ca-09a0-4156-aebe-1d033b33eec0/ConvAmi.AACME.API-Scope']],
        ['https://devconvamiaafcmeapi.azurewebsites.net/*', ['api://a6e530ca-09a0-4156-aebe-1d033b33eec0/ConvAmi.AACME.API-Scope']],
        ['http://localhost:4200/*', ['api://a6e530ca-09a0-4156-aebe-1d033b33eec0/ConvAmi.AACME.API-Scope']]
    ])
}

