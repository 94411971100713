import { Subject, Subscription, catchError, takeUntil, throwError } from 'rxjs';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { LayoutService } from "./service/app.layout.service";
import { UserAdService } from '../main/service/user-ad.service';
import { NotifierDataService } from './signal-r/notifier-data.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-Notifications-sidebar',
    templateUrl: './app.notifications-sidebar.component.html'
})
export class AppNotificationsSidebarComponent implements OnInit, OnDestroy {

    _notifications: any[] = [];

    userAD: any;
    hasAaccess: boolean = false;
    loading: boolean = false;

    private subscription: Subscription;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _router: Router,
        private _userAD: UserAdService,
        private _notification: NotifierDataService,
        public layoutService: LayoutService) {
        this._unsubscribeAll = new Subject();
        this._userAD.connectedUserAD$.subscribe(
            {
                next: (value) => {
                    this.userAD = value;
                }
            }
        );
        this._userAD.connectedUserAD$
            .subscribe(
                {
                    next: (_user) => {
                        let groups: string[] = [];
                        _user.userGroups.map(
                            (group: any) => {
                                groups.push(group.displayName);
                            }
                        );
                        if (groups.includes('Executive-Board') && groups.includes('President') || groups.includes('Executive-Board') && groups.includes('Tresorier')) {
                            this.hasAaccess = true;
                            this.Notifications();
                        } else {
                            this.hasAaccess = false;
                        }
                    }
                }
            );
    }

    getShortName(name: string) {
        return name.split(' ').map(n => n[0]).join('');
    }

    ngOnInit(): void {
        this.subscription = this._notification.notifs$
            .subscribe(
                {
                    next: (_newest) => {
                        if (_newest) {
                            _newest.isNewest = true;
                            this._notifications.unshift(_newest);
                        }
                    }
                }
            );

        this.subscription = this._notification.isOpened$
            .subscribe(
                {
                    next: (_isOpened) => {
                        if (_isOpened)
                            this._notifications.map(
                                (_notif: any) => {
                                    if (!_notif.isSeen) {
                                        this._notification.MarkAsSeen(_notif?.id)
                                            .subscribe(
                                                {
                                                    next: (response: any) => {
                                                        this._notifications.map(
                                                            (_afterSeen: any) => {
                                                                if (_afterSeen.id === response.data.id) {
                                                                    _afterSeen.isSeen = true;
                                                                }
                                                            }
                                                        );
                                                    }, complete: () => {
                                                        this._notification.decrement(1);
                                                    }
                                                }
                                            );
                                    }
                                }
                            );
                    }
                }
            );
        this.subscription = this._notification.onReceiveHiddenNotification$
            .subscribe(
                {
                    next: (_notifToHide: any) => {
                        if (_notifToHide) {
                            for (let i = 0; i < this._notifications.length; i++) {
                                if (this._notifications[i].id === _notifToHide.id) {
                                    this._notifications.splice(i, 1);
                                    i--;
                                }
                            }
                        }
                    }
                }
            );
    }

    Notifications() {
        this.loading = true;
        this.subscription = this._notification.Notification()
            .pipe(
                takeUntil(this._unsubscribeAll),
                catchError(err => {
                    return throwError(() => err);
                })
            )
            .subscribe(
                {
                    next: (response: any) => {
                        response.data.sort(function (a: any, b: any) {
                            return new Date(b.operationDate).getTime() - new Date(a.operationDate).getTime();
                        });
                        this._notifications = response.data;

                    },
                    complete: () => {
                        this.loading = false;
                    }
                }
            );
    }

    navigate(notification: any) {
        this._notifications.map(
            (_toDN: any) => {
                if (_toDN.id === notification.id) {
                    _toDN.isNewest = false;
                }
            }
        );
        this._router.navigateByUrl("/app-layout/M-suppliers/ooredoo-area/exceptionnal-purchases/" + notification?.requestId);
        setTimeout(() => {
            this.visible = false;
        }, 500);
    }

    get visible(): boolean {
        return this.layoutService.state.notificationSidebar;
    }

    set visible(_val: boolean) {
        this.layoutService.state.notificationSidebar = _val;
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        // Emit something to stop all Observables
        this._unsubscribeAll.next(null);
        // Complete the notifying Observable to remove it
        this._unsubscribeAll.complete();
    }

}
