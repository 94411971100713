import { Injectable } from '@angular/core';
import { NotifierDataService } from './notifier-data.service';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { LogLevel } from '@microsoft/signalr';
import { BrowserNotifierService } from './browser-notifier.service';
import { MsalService } from '@azure/msal-angular';

class CustomHttpClient extends signalR.DefaultHttpClient {
    constructor() {
        super(console); // the base class wants an signalR.ILogger, I'm not sure if you're supposed to put *the console* into it, but I did and it seemed to work
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotifierService {

    private hubConnection: signalR.HubConnection | undefined;
    SONG: string = "../../../assets/layout/signal.mp3";
    ringtone: any;
    isConnected: boolean = false;

    constructor(
        private _msal: MsalService,
        private _notificationService: BrowserNotifierService,
        private _notifData: NotifierDataService) {
        this._notificationService.requestPermission();
        this.ringtone = new Audio();
        this.ringtone.src = this.SONG;
        this.ringtone.load();
    }

    public startConnection = () => {
        const user = this._msal.instance.getAllAccounts()[0]?.localAccountId;
        if (user) {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(`${environment.hub}NotificationHub/${user}`, {
                    withCredentials: false,
                    headers: {
                        "hub-access-token": user
                    },
                    httpClient: new CustomHttpClient()
                })
                .configureLogging(LogLevel.Information)
                .build();

            this.hubConnection
                .start()
                .then(
                    () => {
                        console.log('Connection started | connectionId:' + ' ' + this.hubConnection?.connectionId);
                    }
                )
                .catch(err => {
                    console.error('Error while starting connection: ' + err);
                });

            this.hubConnection.on('ReceiveNotification', (_notif) => {
                if (_notif) {
                    let _newest: any = {
                        id: _notif.id,
                        agreementName: _notif.agreementName,
                        operationDate: _notif.operationDate,
                        requestType: _notif.requestType,
                        treatmentType: _notif.treatmentType,
                        isSeen: false,
                        requester: _notif.requester,
                        requestId: _notif.requestId
                    };
                    this._notifData.RefreshNotifications(_newest);
                    this.ringtone.play();
                    this.Notify(_newest);
                }
            });

            this.hubConnection.on('ReceiveHiddenNotification', (_off) => {
                debugger
                if (_off)
                    this._notifData.HideNotification(_off);
            });
        }

    }

    Notify(notif: any) {
        let data: Array<any> = [];
        data.push({
            'title': '___ | ' + notif.agreementName,
            'alertContent': notif.operationDate
        });
        this._notificationService.generateNotification(data);
    }
}
