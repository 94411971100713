
export const environment = {
    production: true,
    apiUrl: 'https://prodconvamiaafcmeapi.azurewebsites.net/ConvAmiAAFCME/',
    apiReferentiel: 'https://prodconvamireferentielapi.azurewebsites.net/api/',
    hub: 'https://prodconvamireferentielapi.azurewebsites.net/',
    bookingUrl: 'https://prodbookingaafcmeweb.azurewebsites.net',
    resaApiUrl: 'https://prodresaaafcmewebapp.azurewebsites.net/',
    nopCommerce: 'https://resa-aafcme.convami.com.tn/',
    suppApiUrl: 'https://prodconvamisupplierapi.azurewebsites.net/ConvAmiSupplier/',
    encryptKey: 1203199320052021,
    encryptKeyIV: 1203199320052021
};
