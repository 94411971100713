import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotifierDataService implements OnDestroy {

    private _notifsData: any = null;
    private notifsData = new BehaviorSubject<any>(this._notifsData);
    notifsData$ = this.notifsData.asObservable();

    private _notifs: any = null;
    private notifs = new BehaviorSubject<any>(this._notifs);
    notifs$ = this.notifs.asObservable();

    private _seen: any = null;
    private seen = new BehaviorSubject<any>(this._seen);
    seen$ = this.seen.asObservable();

    private _open: any = null;
    private open = new BehaviorSubject<any>(this._open);
    isOpened$ = this.open.asObservable();

    private _receiveHiddenNotification: any = null;
    private receiveHiddenNotification = new BehaviorSubject<any>(this._receiveHiddenNotification);
    onReceiveHiddenNotification$ = this.receiveHiddenNotification.asObservable();

    private _unsubscribeAll: Subject<any>;

    constructor(
        private msal: MsalService,
        private _http: HttpClient
    ) {
        this._unsubscribeAll = new Subject();
    }

    Notification() {

        let params = new HttpParams();
        const user = this.msal.instance.getAllAccounts()[0]?.localAccountId;
        params = params.append('userId', user);
        return this._http.get(`${environment.apiReferentiel}Notification/MyNotifications`, { params: params }
        );

    }

    decrement(obj: any) {
        this._seen = obj;
        this.seen.next(obj);
    }

    Open(isOpen: boolean) {
        this._open = isOpen;
        this.open.next(isOpen);
    }

    HideNotification(obj: any) {
        this._receiveHiddenNotification = obj;
        this.open.next(this.receiveHiddenNotification);
    }

    MarkAsSeen(notificationId: number) {
        const user = this.msal.instance.getAllAccounts()[0]?.localAccountId;
        return this._http.post(`${environment.apiReferentiel}Notification/MarkNotificationAsSeen`, { userId: user, NotificationId: notificationId }
        );
    }

    RefreshNotifications(notif: any) {
        this._notifs = notif;
        this.notifs.next(notif);
    }

    patchNotif(notifs: any) {
        this._notifsData = notifs;
        this.notifsData.next(notifs);
    }

    ngOnDestroy(): void {
        // Emit something to stop all Observables
        this._unsubscribeAll.next(null);
        // Complete the notifying Observable to remove it
        this._unsubscribeAll.complete();
    }

}
