<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-profile-sidebar w-full sm:w-28rem"
    [showCloseIcon]="false"
>
    <div
        class="layout-rightmenu overflow-x-hidden"
        [ngClass]="{
            'layout-rightmenu-active': layoutService.state.notificationSidebar
        }"
    >
        <div
            class="flex justify-content-center align-items-center gap-2 prevent-select"
        >
            <img src="assets/demo/images/notifications.png" width="35" />
            <h4 class="text-700">Centre des notifications</h4>
        </div>
        <div class="col-12">
            <div class="card p-0">
                <div
                    class="timeline-header p-3 flex justify-content-between align-items-center"
                >
                    <p class="m-0 font-medium text-primary prevent-select">
                        Historique des notifications
                    </p>
                    <div class="header-icons">
                        <p-button
                            icon="pi pi-refresh"
                            styleClass="p-button-rounded p-button-text"
                            (onClick)="Notifications()"
                        ></p-button>
                    </div>
                </div>
                <div style="overflow-y: scroll; height: 45.5rem">
                    <ul
                        *ngIf="!loading"
                        class="mt-2 flex flex-row md:flex-column w-full gap-1 md:gap-2 list-none m-0 p-0 overflow-auto"
                    >
                        <li
                            pRipple
                            *ngFor="
                                let notification of _notifications;
                                let i = index;
                                let first = first
                            "
                            class="bg-notif hover:surface-hover cursor-pointer select-none p-3 w-full transition-duration-150 border-round flex align-items-center justify-content-center md:justify-content-start md:flex-1 flex-auto"
                            [ngClass]="{
                                'bg-notif-seen': notification.isSeen,
                                'bg-notif-notseen': !notification.isSeen
                            }"
                            (click)="navigate(notification)"
                        >
                            <div class="col-12 p-0" style="display: grid">
                                <div
                                    class="flex justify-content-end gap-2 align-items-center px-2"
                                >
                                    <div class="font-medium">Source</div>
                                    <span>
                                        <span>
                                            {{
                                                notification?.requester
                                                    ?.jobTitle
                                            }}
                                            |
                                            {{
                                                notification?.requester
                                                    ?.location
                                            }}</span
                                        >
                                    </span>
                                </div>
                                <img
                                    class="ml-2 mt-2"
                                    *ngIf="notification?.isNewest"
                                    src="assets/demo/images/newestn.png"
                                    width="40"
                                />
                                <div class="flex align-items-center">
                                    <div
                                        class="flex align-items-center gap-2 ml-2"
                                    >
                                        <span
                                            *ngIf="notification?.requestType"
                                            class="text-lg text-primary font-medium"
                                            >{{
                                                notification?.requestType
                                                    .displayName
                                            }}</span
                                        >
                                        <span
                                            *ngIf="!notification?.requestType"
                                            class="text-lg text-primary font-medium"
                                            >UNKOWN</span
                                        >
                                        <span
                                            class="text-800 font-medium"
                                            style="text-transform: lowercase"
                                            >sous la convention&nbsp;{{
                                                notification?.agreementName
                                            }}</span
                                        >
                                    </div>
                                </div>
                                <div
                                    class="flex mt-2 justify-content-between align-items-center px-2"
                                >
                                    <div class="flex gap-2 align-items-center">
                                        <img
                                            *ngIf="
                                                notification?.treatmentType
                                                    .key === 0
                                            "
                                            src="assets/demo/images/nn.png"
                                            width="20"
                                        />
                                        <img
                                            *ngIf="
                                                notification?.treatmentType
                                                    .key === 1
                                            "
                                            src="assets/demo/images/notif-pending.png"
                                            width="20"
                                        />
                                        <img
                                            *ngIf="
                                                notification?.treatmentType
                                                    .key === 2
                                            "
                                            src="assets/demo/images/notif-accepted.png"
                                            width="20"
                                        />
                                        <img
                                            *ngIf="
                                                notification?.treatmentType
                                                    .key === 3
                                            "
                                            src="assets/demo/images/notif-rejected.png"
                                            width="20"
                                        />
                                        <span class="text-700">{{
                                            notification?.treatmentType
                                                .displayName
                                        }}</span>
                                    </div>
                                    <span class="text-500">
                                        {{
                                            notification.operationDate
                                                | dateTimeFormat
                                                    : "YYYY-MM-DD HH:mm:ss"
                                        }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul
                        *ngIf="loading"
                        class="mt-2 flex flex-row md:flex-column w-full gap-1 md:gap-2 list-none m-0 p-0 overflow-auto"
                    >
                        <li
                            pRipple
                            *ngFor="let _ of [].constructor(6)"
                            class="flex align-items-center justify-content-center md:justify-content-start md:flex-1 flex-auto"
                            (click)="navigate(_)"
                        >
                            <p-skeleton
                                styleClass="hover:surface-hover cursor-pointer select-none p-3  transition-duration-150 border-round"
                                width="326.32px"
                                height="96.01px"
                            ></p-skeleton>
                        </li>
                    </ul>
                    <ul *ngIf="!loading && _notifications.length === 0">
                        <div
                            class="mt-4 pt-5 py-5 flex justify-content-center prevent-select"
                        >
                            <img
                                src="assets/demo/images/empty-notif.png"
                                width="80"
                            />
                        </div>
                        <div
                            class="flex justify-content-center mt-2 text-400 text-normal prevent-select"
                        >
                            Aucune notification n'a été trouvé
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>
