import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { MsalGuard } from '@azure/msal-angular';


const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: 'app-layout',
        component: AppLayoutComponent,
        canActivate: [MsalGuard],
        children: [
            { path: 'M-global-view', data: { breadcrumb: "Module vue 360", fragment: 'assets/demo/images/metaverse.png' }, loadChildren: () => import('./main/components/m-global-vue/m-global-vue.module').then(m => m.MGlobalVueModule), canActivate: [MsalGuard] },
            { path: 'M-back-office', data: { breadcrumb: "Module back office", fragment: 'assets/demo/images/adaptive.png' }, loadChildren: () => import('./main/components/m-back-office/m-back-office.module').then(m => m.MBackOfficeModule), canActivate: [MsalGuard] },
            { path: 'M-purchase', data: { breadcrumb: "Module achat", fragment: 'assets/demo/images/management.png' }, loadChildren: () => import('./main/components/m-purchase/m-purchase.module').then(m => m.MPurchaseModule), canActivate: [MsalGuard] },
            { path: 'M-Aafcme-services', data: { breadcrumb: "Module service aafcme", fragment: 'assets/demo/images/share.png' }, loadChildren: () => import('./main/components/aafcme-services/aafcme-services.module').then(m => m.AafcmeServicesModule), canActivate: [MsalGuard] },
            { path: 'M-suppliers', data: { breadcrumb: "Module fournisseurs", fragment: 'assets/demo/images/pipeline.png' }, loadChildren: () => import('./main/components/suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [MsalGuard] },
            { path: 'M-market-place', data: { breadcrumb: "Module vente", fragment: 'assets/demo/images/bags.png' }, loadChildren: () => import('./main/components/m-market-place/m-market-place.module').then(m => m.MarketPlaceModule), canActivate: [MsalGuard] },
            // { path: 'M-traveling-aboard', data: { breadcrumb: "Voyages à l'étranger", fragment: 'assets/demo/images/Voyage.png' }, loadChildren: () => import('./main/components/traveling-abroad/traveling-abroad.module').then(m => m.TravelingAbroadModule), canActivate: [MsalGuard] },
            { path: 'M-azure-function', data: { breadcrumb: "Module Injection Data"}, loadChildren: () => import('./main/components/azure-function/azure-function.module').then(m => m.AzureFunctionModule), canActivate: [MsalGuard] },
            { path: 'M-interventions-manuelles', data: { breadcrumb: "Module interventions Manuelles", fragment: 'assets/demo/images/Interventions-manuelles.png' }, loadChildren: () => import('./main/components/interventions-manuelles/interventions-manuelles.module').then(m => m.InterventionsManuellesModule), canActivate: [MsalGuard] },
            { path: 'M-invoice-services', data: { breadcrumb: "Module Facturation", fragment: 'assets/demo/images/invoice.png' }, loadChildren: () => import('./main/components/invoice-service/invoice-service.module').then(m => m.InvoiceServiceModule), canActivate: [MsalGuard] },
        ]
    }
    ,
    { path: '', loadChildren: () => import('./main/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./main/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: 'access-denied', loadChildren: () => import('./main/components/access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'offline-exception', loadChildren: () => import('./main/components/offline-exception/offline-exception.module').then(m => m.OfflineExceptionModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes, routerOptions
    )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
